import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationApp } from "./NotificationApp";
import { useLocation, useNavigate } from "react-router-dom";
import { startGetHasPendingSurveys } from "../redux/slices/users/Surveys";
import Swal from "sweetalert2";

const swalAlert = () =>
    Swal.fire({
        title: "<strong>Acceso limitado</strong>",
        html: `
            <p><strong>Tienes encuestas obligatorias pendientes.</strong></p>
            <p>Por favor, completa las encuestas para desbloquear el acceso a los demas apartados.</p>
        `,
        icon: "warning",
        confirmButtonText: "Entendido",
        backdrop: true,
        padding: "2em",
    });

export const PendingSurveys = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { session, user } = useSelector((state) => state.auth);
    const { hasPendingSurveys } = useSelector((state) => state.surveys);

    useEffect(() => {
        if (session === "student") {
            dispatch(startGetHasPendingSurveys());
        }
    }, []);

    useEffect(() => {
        const pathname = location.pathname;
        const isInSurveysModule = pathname.startsWith("/students/surveys");

        //* Si el usuario es normal, tiene encuestas pendientes y no esta en el modulo de encuestas, redireccionar
        if (
            hasPendingSurveys &&
            !isInSurveysModule &&
            user.status === "NORMAL"
        ) {
            switch (session) {
                case "student":
                    swalAlert();
                    navigate("/students/surveys");
                    break;
            }
        }
    }, [location, hasPendingSurveys]);

    return <></>;
};
